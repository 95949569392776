var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page"},[_c('div',{staticClass:"banner"}),_c('div',{staticClass:"home-swiper"},[_vm._m(0),_c('div',{staticClass:"swiper"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"leftSwi",on:{"click":function($event){return _vm.handleSwiper('left')}}},[_c('div',{staticClass:"left"})]),_c('div',{staticClass:"main"},[(_vm.index==0)?_c('div',{staticClass:"list"},[_vm._m(1),_vm._m(2)]):_vm._e(),(_vm.index==1)?_c('div',{staticClass:"list"},[_vm._m(3),_vm._m(4)]):_vm._e(),(_vm.index==2)?_c('div',{staticClass:"list"},[_vm._m(5),_vm._m(6)]):_vm._e()]),_c('div',{staticClass:"rightSwi",on:{"click":function($event){return _vm.handleSwiper('right')}}},[_c('div',{staticClass:"right"})])]),_c('div',{staticClass:"indicator"},[_c('div',{staticStyle:{"margin-right":"30px"},style:(_vm.index == 0 ? 'background: #003D8E;' : '')}),_c('div',{staticStyle:{"margin-right":"30px"},style:(_vm.index == 1 ? 'background: #003D8E;' : '')}),_c('div',{style:(_vm.index == 2 ? 'background: #003D8E;' : '')})])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"swiper-title"},[_c('div',{staticClass:"title1"},[_vm._v("全部分类")]),_c('div',{staticClass:"title2"},[_vm._v("ALL CATEGORIES")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{attrs:{"src":require("../../../public/img/item1-top.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{attrs:{"src":require("../../../public/img/item1-bottom.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{attrs:{"src":require("../../../public/img/item2-top.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{attrs:{"src":require("../../../public/img/item2-bottom.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{attrs:{"src":require("../../../public/img/item3-top.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{attrs:{"src":require("../../../public/img/item3-bottom.png"),"alt":""}})])
}]

export { render, staticRenderFns }