<template>
  <div class="page">
    <div class="page-banner">
      <div class="page-banner-box">
        <!-- <img src="../../../public/img/mebanner3x.png" /> -->
        <img  class="company" src="../../../public/img/company.png" />
        <div class="introduction">
          <div class="top">
            <div class="left">
              <div class="line1"></div>
              <div class="line2"></div>
            </div>
            <div  class="right">
              <div  class="text1">以供应链为基础的</div>
              <div  class="text2">一站式五金采购服务平台</div>
            </div>
          </div>
          <div  class="bottom">
            <div>
              <div class="companyIntro">
                五金易批隶属于青岛基本工信息技术有限公司，是国内领先的五金采购平台。五金易批扎根街道乡镇，服务于全国乡镇五
                金门店，品类齐全。让五金门店直接对接上游工厂，提供一站式采购及配送服务。
              </div>              
            </div>
            <div>
              <div class="companyIntro">
                拥有齐全的五金品类参数数据库，快速精准匹配您所需五金商品。全程专人服务，配备专属服务商，交易步骤全程可控，
                轻松收货，售后无忧。
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="page-tags">
      <div class="page-tags-title">
        <div class="ptt-line"></div>
        <div class="ptt-text">易批文化</div>
      </div>
      <div class="page-tags-box">
        <div class="tag">
          <div class="icon"></div>
          <div class="name">愿景</div>
          <div class="subname">让五金采购更简单</div>
        </div>
        <div class="tag">
          <div class="icon2"></div>
          <div class="name">核心价值观</div>
          <div class="subname">开放共享 共创价值</div>
        </div>
        <div class="tag">
          <div class="icon3"></div>
          <div class="name">使命</div>
          <div class="subname3">
            成为国内领先的下沉市场 批发行业的交易与服务平台
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {},
};
</script>

<style scoped lang="less">
.page {
  width: 100%;
  .page-banner {
    width: 100%;
    height: 440px;
    background: #fafafa;
    overflow: hidden;
    .page-banner-box {
      width: 1400px;
      height: 340px;
      margin: 50px auto;
      display: flex;
      // justify-content:space-between;
      .company{
        width: 510px;
        height: 340px;

      }
      .introduction{
        margin-left: 40px;
        flex: 1;
        .top{
          display: flex;
          margin-bottom: 38px;
          .left{
            width: 5px;
            height: 88px;
            .line1{
              width: 5px;
              height: 44px;
              background: #FFE200;
            }
            .line2{
              width: 5px;
              height: 44px;
              background: #003D8E;
            }
          }
          .right{
            margin-left: 26px;
            .text1{
              font-family: 'PingFang SC';
              font-style: normal;
              font-weight: 400;
              font-size: 28px;
              line-height: 39px;
              color: #FEE101;
            }
            .text2{
              font-family: 'PingFang SC';
              font-style: normal;
              font-weight: 400;
              font-size: 32px;
              line-height: 45px;
              color: #333333;

            }
          }
        }
        .bottom{
          width: 848px;
          height: 150px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .companyIntro{
            font-family: 'PingFang SC';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 30px;
            color: #333333;
          }
        }
      }
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .page-tags {
    width: 1400px;
    height: 552px;
    margin: 0 auto;
    background: #ffffff;
    .page-tags-title {
      display: flex;
      margin-top: 50px;
      .ptt-line {
        width: 5px;
        height: 45px;
        background-color: #003b84;
        margin-right: 20px;
      }
      .ptt-text {
        font-family: "PingFang SC";
        font-style: normal;
        font-weight: 400;
        font-size: 32px;
        line-height: 45px;
        /* identical to box height */
        text-align: center;

        color: #333333;
      }
    }
    .page-tags-box {
      margin-top: 72px;
      display: flex;
      justify-content: space-around;
      .tag {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .icon {
          width: 180px;
          height: 180px;
          background: url(../../../public/img/bird3x.png);
          background-size: cover;
        }
        .icon2 {
          width: 180px;
          height: 180px;
          background: url(../../../public/img/diamond3x.png);
          background-size: cover;
        }
        .icon3 {
          width: 180px;
          height: 180px;
          background: url(../../../public/img/badge3x.png);
          background-size: cover;
        }
        .name {
          font-family: "PingFang SC";
          font-style: normal;
          font-weight: 400;
          font-size: 28px;
          color: #333333;
          margin-top: 27px;
        }
        .subname {
          font-family: "PingFang SC";
          font-style: normal;
          font-weight: 400;
          font-size: 20px;
          color: #666666;
          margin-top: 23px;
          height: 61px;
        }
        .subname3 {
          font-family: "PingFang SC";
          font-style: normal;
          font-weight: 400;
          font-size: 20px;
          color: #666666;
          margin-top: 23px;
          // line-height: 28px;
          text-align: center;
          width: 240px;
          height: 61px;
        }
      }
    }
  }
}
</style>
