var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home"},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"home-swiper"},[_vm._m(2),_c('div',{staticClass:"swiper"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"left",on:{"click":function($event){return _vm.handleSwiper('left')}}}),_c('div',{staticClass:"main"},[_c('ul',{ref:"ul"},[_vm._m(3),_vm._m(4),_vm._m(5)])]),_c('div',{staticClass:"right",on:{"click":function($event){return _vm.handleSwiper('right')}}})]),_c('div',{staticClass:"indicator"},[_c('div',{staticStyle:{"margin-right":"30px"},style:(_vm.index == 0 ? 'background: #003D8E;' : '')}),_c('div',{style:(_vm.index == 1 ? 'background: #003D8E;' : '')})])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"banner"},[_c('div',{staticClass:"bannerContent"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"title"},[_vm._v("一站式五金采购服务平台")]),_c('div',{staticClass:"line"}),_c('div',{staticClass:"explain"},[_vm._v(" 拥有齐全的五金品类参数数据库，快速精准匹配您所需五金商品； ")]),_c('div',{staticClass:"explain"},[_vm._v(" 全程专人服务，配备专属服务商，交易步骤全程可控，轻松收货，售后无忧。 ")])]),_c('img',{staticClass:"phoneImage",attrs:{"src":require("../../../public/img/phoneContent.png"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home-tags"},[_c('div',{staticClass:"tags-title"},[_c('div',{staticClass:"title1"},[_vm._v("个性化服务")]),_c('div',{staticClass:"title2"},[_vm._v("PERSONALIZED SERVICE")])]),_c('div',{staticClass:"tag-items"},[_c('div',{staticClass:"item"},[_c('div',{staticClass:"bg1"}),_c('div',{staticClass:"text"},[_vm._v(" 快速找商品 ")])]),_c('div',{staticClass:"item"},[_c('div',{staticClass:"bg2"}),_c('div',{staticClass:"text"},[_vm._v(" 商品推荐 ")])]),_c('div',{staticClass:"item"},[_c('div',{staticClass:"bg3"}),_c('div',{staticClass:"text"},[_vm._v(" 交易保障 ")])]),_c('div',{staticClass:"item"},[_c('div',{staticClass:"bg4"}),_c('div',{staticClass:"text"},[_vm._v(" 高性价比 ")])]),_c('div',{staticClass:"item"},[_c('div',{staticClass:"bg5"}),_c('div',{staticClass:"text"},[_vm._v(" 数据分析 ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"swiper-title"},[_c('div',{staticClass:"title1"},[_vm._v("场景应用")]),_c('div',{staticClass:"title2"},[_vm._v("SCENARIO APPLICATION")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('img',{attrs:{"src":require("../../../public/img/home-swiper-1.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('img',{attrs:{"src":require("../../../public/img/home-swiper-2.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('img',{attrs:{"src":require("../../../public/img/home-swiper-1.png"),"alt":""}})])
}]

export { render, staticRenderFns }