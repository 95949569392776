<template>
  <div class="index">
    <div class="index-head">
      <div class="index-head-logo"></div>
      <div class="index-head-tabs">
        <div
          :class="{ 'tabs-item-active': active == 1 }"
          class="tabs-item"
          @click="tabHandle(1)"
        >
          首页
        </div>
        <div
          :class="{ 'tabs-item-active': active == 2 }"
          class="tabs-item"
          @click="tabHandle(2)"
        >
          商品品类
        </div>
        <div
          :class="{ 'tabs-item-active': active == 3 }"
          class="tabs-item"
          @click="tabHandle(3)"
        >
          关于我们
        </div>
        <div
          :class="{ 'tabs-item-active': active == 4 }"
          class="tabs-item"
          @click="tabHandle(4)"
        >
          招商加盟
        </div>
        <div
          :class="{ 'tabs-item-active': active == 5 }"
          class="tabs-item"
          @click="tabHandle(5)"
        >
          联系我们
        </div>
        <div class="phone">
          <div class="icon"></div>
          <div class="phone-num">4008 358 678</div>
        </div>
      </div>
    </div>
    <div class="main">
      <!-- 首页 -->
      <Home v-if="active == 1"></Home>
      <!-- 商品品类 -->
      <Categody v-if="active == 2"></Categody>
      <!-- 关于我们 -->
      <AboutUs v-if="active == 3"></AboutUs>
      <!-- 招商加盟 -->
      <JoinInvestment v-if="active == 4"></JoinInvestment>
      <!-- 联系我们 -->
      <ContactUs v-if="active == 5"></ContactUs>
    </div>

    <div class="foot">
      <div class="foot-box">
        <div class="foot-box-title">关于我们</div>
        <div class="foot-box-phone">
          <div class="icon"></div>
          <div class="phone-num">4008 358 678</div>
        </div>
        <div class="foot-box-location">
          <div class="icon"></div>
          <div class="location">
            山东省临沂市河东五金城E区60号五金易批
          </div>
        </div>
      </div>
      <div class="foot-bottom">
        <div class="left">版权所有©青岛基本工信息技术有限公司</div>
        <div class="right"><a href="https://beian.miit.gov.cn/" target="_blank">鲁ICP备2022018853号-1</a></div>
      </div>
    </div>
  </div>
</template>

<script>
import Home from "./components/Home.vue";
import Categody from "./components/Category.vue";
import AboutUs from "./components/AboutUs.vue";
import JoinInvestment from "./components/JoinInvestment.vue";
import ContactUs from "./components/ContactUs.vue";
export default {
  components: {
    Home,
    Categody,
    AboutUs,
    JoinInvestment,
    ContactUs,
  },
  data() {
    return {
      active: 1,
    };
  },
  methods: {
    tabHandle(index) {
      this.active = index;
    },
  },
};
</script>

<style lang="less" scoped>
.index {
  width: 100%;
  height: 100%;
  .index-head {
    width: 100%;
    height: 100px;
    background: #ffffff;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.02);
    display: flex;
    align-items: center;
    justify-content: space-around;
    .index-head-logo {
      width: 210px;
      height: 45px;
      background: url("../../public/img/logo3x.png");
      background-size: cover;
    }
    .index-head-tabs {
      display: flex;
      .tabs-item {
        width: 112px;
        height: 100px;
        font-family: "PingFang SC";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        color: #333333;
        text-align: center;
        line-height: 100px;
      }
      .tabs-item-active {
        background: linear-gradient(270deg, #2b67ff 0%, #1597ff 100%);
        font-family: "PingFang SC";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        color: #ffffff;
      }
      .phone {
        display: flex;
        align-items: center;
        margin-left: 35px;
        .icon {
          background: url("../../public/img/phone3x.png");
          background-size: cover;
          width: 23.5px;
          height: 23.5px;
        }
        .phone-num {
          // width: 93px;
          height: 20px;
          font-family: "PingFang SC";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          text-align: right;
          margin-left: 11px;
        }
      }
    }
  }
  .main {
    width: 100%;
  }
  .foot {
    width: 100%;
    height: 231px;
    background: #f0f0f0;
    overflow: hidden;
    .foot-box {
      margin: 0 auto;
      width: 1400px;
      height: 173px;
      border-bottom: 1px#CDCDCD solid;
      overflow: hidden;
      .foot-box-title {
        font-family: "PingFang SC";
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 25px;
        color: #333333;
        margin-top: 30px;
      }
      .foot-box-phone {
        display: flex;
        align-items: center;
        margin-top: 28px;
        .icon {
          background: url("../../public/img/phone2_3.png");
          background-size: cover;
          width: 13px;
          height: 13px;
        }
        .phone-num {
          // width: 93px;
          height: 20px;
          font-family: "PingFang SC";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: #666666;
          margin-left: 11px;
        }
      }
      .foot-box-location {
        display: flex;
        align-items: center;
        margin-top: 20px;
        .icon {
          background: url("../../public/img/location3x.png");
          background-size: cover;
          width: 11px;
          height: 14px;
        }
        .location {
          font-family: "PingFang SC";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          // line-height: 20px;
          color: #666666;
          margin-left: 13px;
        }
      }
    }
    .foot-bottom {
      margin: 0 auto;
      width: 1400px;
      height: 57px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .left {
        font-family: "PingFang SC";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 17px;
        text-align: center;
        color: #999999;
      }
      .right {
        font-family: "PingFang SC";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 17px;
        text-align: right;
        color: #999999;
      }
    }
  }
}
</style>
