<template>
  <div class="page">
    <div class="page-top">
      <div class="page-top-box">
        <div class="top">
          <div class="left">
            <div class="title">
              服务中心加盟
            </div>
            <div class="introduction">
              零仓储，区域帮扶专人协助；后台大数据精准分析，精细化运作；售后服务快速响应，区域
              保护；一对一服务培训指导，成熟案例推广。
            </div>
            <div class="tips">
              <span class="bold"> 加盟资质：</span>
              <span class="normal"> 营业执照、银行资料、个人实名信息联系方等。</span>
            </div>
          </div>
          <div class="right"></div>
        </div>
        <div class="bottom">
          <div class="left"></div>
          <div class="right">
            <div class="title">
              供应商加盟
            </div>
            <div class="introduction">
              全国客户资源，聚量采购；精准链接上下游，下沉乡镇；数字化平台运营，掌握最新市场动
              向；实时商品定价上传，高效快捷；央行合作银联系统，一键提现，安全有保障；入驻免费，
              佣金透明，打造良好的商业氛围。
            </div>
            <div class="tips">
              <span class="bold"> 加盟资质：</span>
              <span class="normal"> 法人信息、控股股东信息、受益人信息、营业执照信息、营业场所照片、个人账
                户或公司帐户信息等。
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="page-bottom">
      <div class="page-bottom-title">即刻开启采购数字化之旅</div>
      <div class="page-bottom-subtitle">
        START THE JOURNEY OF PURCHASING DIGITALIZATION NOW
      </div>
      <div class="page-bottom-list">
        <div class="list-item">
          <div class="item-name">企业名称</div>
          <div class="item-content">
            <input type="text" placeholder="请输入企业名称" />
          </div>
        </div>
        <div class="list-item">
          <div class="item-name">联系人</div>
          <div class="item-content">
            <input type="text" placeholder="请输入姓名" />
          </div>
        </div>
        <div class="list-item">
          <div class="item-name">电话</div>
          <div class="item-content">
            <input type="text" placeholder="请输入手机号码" />
          </div>
        </div>
        <div class="list-item">
          <div class="item-name">邮箱</div>
          <div class="item-content">
            <input type="text" placeholder="请输入邮箱地址" />
          </div>
        </div>
      </div>
      <div class="page-button">提交信息</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="less" scoped>
.page {
  width: 100%;

  .page-top {
    width: 100%;
    height: 1062px;
    box-sizing: border-box;
    padding: 44px 0 50px;
    background-color: #fafafa;
    overflow: hidden;

    .page-top-box {
      width: 1400px;
      height: 961px;
      margin: auto;
      // background: url(../../../public/img/zhaoshang3x.png);
      // background-size: cover;
      margin-top: 44px;

      .top {
        width: 1400px;
        // width: 100%;
        height: 350px;
        background: #FFFFFF;
        box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.08);
        margin-top: 114px;
        margin-bottom: 154px;
        display: flex;
        flex-direction: row;

        .left {
          width: 651px;
          height: 210px;
          margin-left: 109px;
          margin-right: 101px;
          padding: 50px 0 90px;
          .introduction {
            font-family: 'PingFang SC';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 200%;
            color: #666666;
            margin: 20px 0 52px;
          }
        }
        .right {
          width: 397px;
          height: 433px;
          background: url(../../../public/img/servePhone.png);
          background-size: contain;
          background-repeat: no-repeat;
          position: relative;
          bottom: 114px;
        }
      }

      .bottom {
        width: 1400px;
        height: 350px;
        background: #FFFFFF;
        box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.08);
        display: flex;
        flex-direction: rows;
        padding-left: 150px;
        box-sizing: border-box;
        .left {
          width: 397px;
          height: 436px;
          background: url(../../../public/img/sellerPhone.png);
          background-size: contain;
          background-repeat: no-repeat;
          position: relative;
          bottom: 114px;
        }

        .right {
          width: 651px;
          height: 242px;
          padding: 50px 0 58px;
          margin-left: 134px;
          .introduction {
            font-family: 'PingFang SC';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 200%;
            color: #666666;
            margin: 20px 0 20px;
          }
        }
      }

      .title {
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 500;
        font-size: 30px;
        line-height: 42px;
        color: #333333;
      }



      .tips {
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 200%;
        color: #666666;

        .bold {
          font-weight: 700;

        }
      }
    }
  }

  .page-bottom {
    width: 100%;
    height: 542px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .page-bottom-title {
      font-family: "PingFang SC";
      font-style: normal;
      font-weight: 400;
      font-size: 32px;
      color: #333333;
      margin-top: 70px;
    }

    .page-bottom-subtitle {
      font-family: "PingFang SC";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      color: rgba(102, 102, 102, 0.6);
      margin-top: 10px;
    }

    .page-bottom-list {
      width: 950px;
      height: 156px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-top: 51px;

      .list-item {
        width: 450px;
        height: 56px;
        background: #f8f8f8;
        border-radius: 10px;
        margin-bottom: 44px;
        display: flex;
        align-items: center;

        .item-name {
          width: 92px;
          height: 26px;
          border-right: 1.5px #d9d9d9 solid;
          font-family: "PingFang SC";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 26px;
          text-align: center;
          color: #333333;
        }

        .item-content {
          flex: 1;
          margin-left: 15px;

          input {
            width: 90%;
            font-family: "PingFang SC";
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 26px;
            border: none;
            background-color: #f8f8f8;
            outline: none;
          }

          input::-webkit-input-placeholder {
            font-family: "PingFang SC";
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            color: #cccccc;
          }
        }
      }
    }

    .page-button {
      margin-top: 70px;
      width: 300px;
      height: 50px;
      background: linear-gradient(270deg, #2b67ff 0%, #1597ff 100%);
      border-radius: 25px;
      line-height: 50px;
      text-align: center;
      font-family: "PingFang SC";
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      color: #ffffff;
    }
  }
}</style>
