<template>
  <div class="home">
    <div class="banner">
      <div  class="bannerContent">
        <div class="content">
          <div  class="title">一站式五金采购服务平台</div>
          <div class="line"></div>
          <div class="explain">
              拥有齐全的五金品类参数数据库，快速精准匹配您所需五金商品；
          </div>
          <div class="explain">
              全程专人服务，配备专属服务商，交易步骤全程可控，轻松收货，售后无忧。
          </div>
        </div>
        <img class="phoneImage"  src="../../../public/img/phoneContent.png" alt="">
      </div>
    </div>
    <div class="home-tags">
      <div class="tags-title">
        <div class="title1">个性化服务</div>
        <div class="title2">PERSONALIZED SERVICE</div>
      </div>
      <div class="tag-items">
        <div class="item ">
          <div class=" bg1"></div>
          <div  class="text">
            快速找商品
          </div>
        </div>
        <div class="item ">
          <div class=" bg2"></div>
          <div  class="text">
            商品推荐
          </div>
        </div>
        <div class="item ">
          <div class=" bg3"></div>
          <div  class="text">
            交易保障
          </div>
        </div>
        <div class="item ">
          <div class=" bg4"></div>
          <div  class="text">
            高性价比
          </div>
        </div>
        <div class="item ">
          <div class=" bg5"></div>
          <div  class="text">
            数据分析
          </div>
        </div>
      </div>
    </div>
    <div class="home-swiper">
      <div class="swiper-title">
        <div class="title1">场景应用</div>
        <div class="title2">SCENARIO APPLICATION</div>
      </div>
      <div class="swiper">
        <div class="container">
          <div class="left" @click="handleSwiper('left')"></div>
          <div class="main">
            <ul ref="ul">
              <li>
                <img src="../../../public/img/home-swiper-1.png" alt="" />
              </li>
              <li>
                <img src="../../../public/img/home-swiper-2.png" alt="" />
              </li>
              <li>
                <img src="../../../public/img/home-swiper-1.png" alt="" />
              </li>
              <!-- 左右滑动为了实现一种无缝衔接需要在末尾复制一份第一张图片,渐变式不需要这张图可以去掉 -->
            </ul>
          </div>
          <div class="right" @click="handleSwiper('right')"></div>
        </div>
        <!-- TODO 指示器 -->
        <div class="indicator">
          <div
            :style="index == 0 ? 'background: #003D8E;' : ''"
            style="margin-right: 30px"
          ></div>
          <div :style="index == 1 ? 'background: #003D8E;' : ''"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      index: 0,
    };
  },
  methods: {
    handleSwiper(type) {
      let ul = this.$refs.ul;

      if (type == "left") {
        if (this.index == 0) {
          ul.style.left = "-1100px";
          this.index = 1;
        } else {
          ul.style.left = 0;
          this.index = 0;
        }
      } else {
        if (this.index == 0) {
          ul.style.left = "-1100px";
          this.index = 1;
        } else {
          ul.style.left = 0;
          this.index = 0;
        }
      }
      console.log("ul", ul);
      console.log("index ==>", this.index);
      //   ul.style.left = "-1100px";
    },
  },
};
</script>

<style scoped lang="less">
.home {
  width: 100%;
  .banner {
    width: 100%;
    height: 700px;
    // background: url("../../../public/img/head3x.png");
    background: url("../../../public/img/bannerBg.png");
    background-size: cover;
    display:flex;
    justify-content: center;
    align-items: center;
    .bannerContent{
      width: 1038px;
      height: 531px;
      display:flex;
      justify-content: space-between;
      align-items: center;
      .title{
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 500;
        font-size: 40px;
        line-height: 169%;
        color: #333333;
      }
      .line{
        width: 80px;
        height: 3px;
        background: #333333;
        margin: 20px 0 22px;
      }
      .explain{
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 50px;
        color: #333333;
      }
      .phoneImage{
        width: 347px;
        height: 531px;
      }
    }
  }
  .home-tags {
    width: 100%;
    height: 500px;
    background: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    .tags-title {
      margin-top: 50px;
      .title1 {
        font-family: "PingFang SC";
        font-style: normal;
        font-weight: 400;
        font-size: 32px;
        line-height: 45px;
        text-align: center;
        color: #333333;
      }
      .title2 {
        font-family: "PingFang SC";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        color: #999999;
        margin-top: 10px;
      }
    }
    .tag-items {
      display: flex;
      .item {
        width: 180px;
        height: 225px;
        margin: 0 42.5px;
        margin-top: 70px;
        display: flex;
        flex-direction:column;
      }
      .text{
        margin-top: 20px;
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 25px;
        text-align: center;
        color: #333333;
      }
      .bg1 {
        width: 180px;
        height: 180px;
        background: url("../../../public/img/search_icon1.png");
        background-size: cover;
      }
      .bg2 {
        width: 180px;
        height: 180px;
        background: url("../../../public/img/recommend_icon1.png");
        background-size: cover;
      }
      .bg3 {
        width: 180px;
        height: 180px;
        background: url("../../../public/img/safeguard_icon1.png");
        background-size: cover;
      }
      .bg4 {
        width: 180px;
        height: 180px;
        background: url("../../../public/img/cost_icon1.png");
        background-size: cover;
      }
      .bg5 {
        width: 180px;
        height: 180px;
        background: url("../../../public/img/data_icon1.png");
        background-size: cover;
      }
    }
  }
  .home-swiper {
    width: 100%;
    height: 500px;
    background-color: #fafafa;
    overflow: hidden;
    .swiper-title {
      margin-top: 50px;
      .title1 {
        font-family: "PingFang SC";
        font-style: normal;
        font-weight: 400;
        font-size: 32px;
        line-height: 45px;
        text-align: center;
        color: #333333;
      }
      .title2 {
        margin-top: 10px;
        font-family: "PingFang SC";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        color: #999999;
      }
    }
    .swiper {
      margin-top: 60px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .container {
        // width: 1242px;
        display: flex;
        align-items: center;
        .left {
          width: 19px;
          height: 32.5px;
          background: url("../../../public/img/left_icon.png");
          background-size: cover;
        }
        .main {
          width: 1100px;
          margin: 0 71px;
          height: 216px;
          overflow: hidden;
          position: relative;
          /* 图片基础样式 */
          ul {
            position: absolute;
            top: 0;
            width: 300%;
            padding: 0;
            margin: 0;
            animation: 2s linear;
          }
          li {
            list-style: none;
            float: left;
            margin-top: 22px;
          }
          ul img {
            width: 1100px; /* 保证图片尺寸统一 */
            height: 194px;
          }
        }
        .right {
          width: 19px;
          height: 32.5px;
          background: url("../../../public/img/right_icon.png");
          background-size: cover;
        }
      }
      .indicator {
        width: 50px;
        height: 10px;
        display: flex;
        margin-top: 50px;
        div {
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background: #d9d9d9;
        }
      }
    }
  }
}
@keyframes move {
  0% {
    left: 0;
  }
  100% {
    left: -1100px;
  }
}
</style>
