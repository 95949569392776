<template>
  <div class="page">
    <div class="page-top">
      <div class="page-top-list">
        <div class="list-item">
          <div class="list-item-icon">
            <img src="../../../public/img/location.png" />
          </div>
          <div class="list-item-name">联系地址</div>
          <div class="list-item-subname">
            山东省临沂市兰山区大山路与宏达路交汇处 兰田国际6楼
          </div>
        </div>
        <div class="list-item">
          <div class="list-item-icon">
            <img src="../../../public/img/phone2.png" />
          </div>
          <div class="list-item-name">联系电话</div>
          <div class="list-item-subname">4008 358 678</div>
        </div>
        <div class="list-item">
          <div class="list-item-icon">
            <img src="../../../public/img/email.png" />
          </div>
          <div class="list-item-name">联系邮箱</div>
          <div class="list-item-subname">wjinyp@163.com</div>
        </div>
      </div>
    </div>
    <div class="page-map">
      <div class="map">
        <img src="../../../public/img/map.png" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {},
};
</script>

<style scoped lang="less">
.page {
  width: 100%;
  .page-top {
    width: 100%;
    height: 505px;
    background: linear-gradient(94.95deg, #f9fbff 9.84%, #edf4ff 94.88%);
    .page-top-list {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      .list-item {
        width: 370px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .list-item-icon {
          width: 180px;
          height: 180px;
          background: #ffffff;
          border-radius: 50%;
          box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: 72px;
            height: 72px;
          }
        }
        .list-item-name {
          font-family: "PingFang SC";
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          color: #333333;
          margin-top: 28px;
        }
        .list-item-subname {
          width: 266px;
          height: 44px;
          // line-height: 44px;
          margin-top: 15px;
          text-align: center;
          font-family: "PingFang SC";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          color: #666666;
        }
      }
    }
  }
  .page-map {
    width: 100%;
    height: 700px;
    overflow: hidden;
    .map {
      width: 1200px;
      height: 500px;
      margin: 100px auto;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
